<template>
  <v-row>
    <v-col md="8" cols="12" class="pb-0">
      <v-row>
        <v-col cols="12" md="6" lg="4" class="pb-0">
          <v-text-field
            label="Tìm kiếm"
            placeholder="Nhập để tìm kiếm"
            clearable
            outlined
            hide-details
            dense
            v-model="params.search"
            @keyup.enter="$emit('handle-search')"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="4" style="margin-top: 12px">
      <v-btn
        class="float-md-right mr-md-0 mb-0"
        @click="$emit('handle-search')"
        color="primary"
        style="height: 40px"
        medium
        >Tìm kiếm</v-btn
      >
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: ["params", "options"]
};
</script>
<style lang=""></style>
