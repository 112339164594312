import request from "@/utils/request";
export function index(params) {
    return request({
        url: "/lecturers",
        method: "get",
        params,
    });
}
export function update(id, data) {
    return request({
        url: "/lecturers/" + id,
        method: "put",
        data,
    });
}
export function show(id) {
    return request({
        url: "/lecturers/" + id,
        method: "get",
    });
}
export function store(data) {
    return request({
        url: "/lecturers",
        method: "post",
        data,
    });
}
export function destroy(id) {
    return request({
        url: "/lecturers/" + id,
        method: "delete",
    });
}
