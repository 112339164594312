<template>
  <v-dialog v-model="showDialog" persistent max-width="450px">
    <v-card :loading="loading">
      <v-card-title>
        <span>{{ title }}</span>
      </v-card-title>
      <v-card-text class="py-0">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="form.name"
                  label="Tên"
                  dense
                  :rules="[v => !!v || 'Bạn chưa nhập tên cán bộ']"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  :disabled="editing"
                  v-model="form.email"
                  label="Email"
                  dense
                  :rules="[v => !!v || 'Bạn chưa nhập email']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDialog">Huỷ</v-btn>
        <v-btn v-if="!editing" color="blue darken-1" text @click="createData"
          >Thêm</v-btn
        >
        <v-btn v-else color="blue darken-1" text @click="updateData"
          >Cập nhật</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { store, update } from "@/api/lecturer";
import dialogMixin from "@/mixins/crud/dialog";

export default {
  mixins: [dialogMixin(store, update)],
  computed: {
    title() {
      return !this.editing ? "Thêm mới" : "Cập nhật";
    }
  }
};
</script>
